import { useRef, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { cn } from '../../../../utils/cn';
import { OptionsIcon } from '../../../icons/OptionsIcon';

export interface MenuAction {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
  className?: string;
  variant?: 'default' | 'danger';
}

interface ActionsMenuProps {
  actions: MenuAction[];
  className?: string;
}

export function ActionsMenu({ actions, className }: ActionsMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'bottom-end',
      interactive: true,
      offset: [0, 8],
      visible: isOpen,
      onVisibleChange: (next) => {
        setIsOpen(next);
      },
      trigger: 'click',
    });

  useOutsideClick(tooltipRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleAction = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  return (
    <div className='relative inline-block'>
      <button
        ref={setTriggerRef}
        type='button'
        className='p-2 hover:bg-[#232325] rounded-lg transition-colors'
        onClick={handleToggle}
      >
        <OptionsIcon className='w-5 h-5 fill-current text-white' />
      </button>

      {visible && isOpen && (
        <div
          ref={(node) => {
            setTooltipRef(node);
          }}
          {...getTooltipProps({
            className: cn(
              'z-50 w-45 bg-black text-white rounded-xl p-1 border border-secondary',
              className
            ),
          })}
        >
          {actions.map((action, index) => (
            <button
              key={index}
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                handleAction(action.onClick);
              }}
              className={`w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms text-left ${
                action.variant === 'danger' ? 'text-red-500' : ''
              }`}
            >
              {action.icon}
              {action.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
